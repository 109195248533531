<div class="container flex-row-container">
  <div class="auth-content main-auth-container">
    <div class="flex-row-container align-center align-middle">
      <div class="flex-row-container align-right language-switcher-container">
        <stx-language-switcher class="lang-switcher"></stx-language-switcher>
      </div>
    </div>
    <div class="flex-row-container align-center align-middle">
      <div class="language-switcher-container">
        <img src="assets/images/Logo_ST_solo.svg" class="st-logo" alt="Smile Train Logo" />
        <h4 class="margin-header">{{ 'login.header' | translate }}</h4>
        <p class="text-important">{{ 'login.sub_header' | translate }}</p>
      </div>
    </div>
    <div class="flex-row-container m-b-30 align-middle align-center">
      <div class="align-center language-switcher-container">
        <ng-content> </ng-content>
      </div>
    </div>
    <div class="social-icons footer flex-column-container align-right align-middle">
      <a href="smiletrain.org/medical">smiletrain.org/medical</a>
      <div class="flex-row-container align-middle align-center">
        <a href="https://www.facebook.com/SmileTrain" target="_blank"><mat-icon svgIcon="fb"></mat-icon></a>
        <a href="https://twitter.com/smiletrain" target="_blank"><mat-icon svgIcon="twitter"></mat-icon></a>
        <a href="https://www.instagram.com/smiletrain" target="_blank"><mat-icon svgIcon="insta"></mat-icon></a>
        <a href="https://www.youtube.com/user/TheSmileTrainCharity" target="_blank"><mat-icon svgIcon="yt"></mat-icon></a>
        <a href="https://www.snapchat.com/add/SmileTrainHQ" target="_blank"><mat-icon svgIcon="snap"></mat-icon></a>
      </div>
    </div>
    <div class="flex-row-container align-center">
      <div>备案号：<a href="https://beian.miit.gov.cn">京ICP备14005549号-3</a></div>
    </div>
  </div>
  <div class="st-background">
    <img src="/assets/images/background_2.png" alt="" />
  </div>
</div>
