import { ScopeTreeComponent } from '@/src/app/features/user/components/scope-tree/scope-tree.component';
import { Scope } from '@/src/app/features/user/models/scope.model';
import { Profile } from '@/src/app/features/user/models/user-data.model';
import { UserService } from '@/src/app/features/user/services/user.service';
import { SelectOption, SelectOptions } from '@shared/components/form/components/select/select.model';
import { InstanceArea } from '@/src/app/shared/models/stxconfiguration.model';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, merge } from 'rxjs';
import { Item } from 'src/app/shared/models/item.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { chinaScopeId, qaReviewerRoleIds, surgicalQaReviewerSelectValue } from '@utils/constants.utils';

@Component({
  selector: 'stx-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  roles: Item[];
  geoScopes: Scope[];
  orgTreeScopes: Scope[];
  checkedOrg: Scope[];
  checkedGeo: Scope[];
  instanceAreaList: SelectOptions;
  readonly maximumProfileNameLength: number = 255;
  profileForm = new UntypedFormGroup({
    selectedRole: new UntypedFormControl(null, StxValidators.required),
    isDomainAccess: new UntypedFormControl(false),
    profileNameFormControl: new UntypedFormControl(null, Validators.maxLength(this.maximumProfileNameLength)),
    designatedArea: new UntypedFormControl(InstanceArea.GLOBAL, StxValidators.required)
  });

  get isDomainAccess(): boolean {
    return this.profileForm.controls.isDomainAccess.value;
  }
  isGeoScopeDisabled = false;
  activeProfileForLogUser: Profile;
  userProfile: Profile;
  domainScope: Scope;
  @ViewChild('geotree') geoTreeComponent: ScopeTreeComponent;
  @ViewChild('orgtree') orgTreeComponent: ScopeTreeComponent;
  @Input()
  set profile(profile: Profile) {
    if (profile) {
      const domainScopeId = 1;
      this.userProfile = profile;
      this.profileForm.controls.selectedRole.setValue(profile.role.id);
      this.profileForm.controls.profileNameFormControl.setValue(profile.name);
      this.profileForm.controls.designatedArea.setValue(profile.designatedArea);
      this.checkedGeo = profile.scopes.filter(scope => scope.type === 'COUNTRY' || scope.type === 'REGION');
      this.checkedOrg = profile.scopes.filter(scope => scope.type === 'PARTNER' || scope.type === 'TREATMENT_CENTER');
      if (!!profile.scopes && profile.scopes.length > 0 && profile.scopes[0].id === domainScopeId) {
        this.profileForm.controls.isDomainAccess.setValue(true);
      }
      this.cd.detectChanges();
    }
  }

  constructor(private userService: UserService, private cd: ChangeDetectorRef, private readonly wsHelperService: WsHelperService) {}

  ngOnInit() {
    this.getUserData();
    this.initializeInstanceAreaOptions();
    this.setDomainAccessWhenSelectedSurgicalQaReviewer();
    this.setCountrySelectionWhenQaReviewerAndChinaInstance();
    this.setDomainAccessDisabledWhenSelectedChinaInstance();
  }

  initializeInstanceAreaOptions() {
    this.instanceAreaList = [
      {
        id: InstanceArea.GLOBAL,
        name: InstanceArea.GLOBAL
      } as SelectOption,
      {
        id: InstanceArea.CHINA,
        name: InstanceArea.CHINA
      } as SelectOption
    ];
  }

  getUserData() {
    this.wsHelperService
      .callWithSpinner(
        forkJoin([
          this.userService.getRoles(),
          this.userService.getGeoScope(),
          this.userService.getOrgTreeScope(),
          this.userService.getDomainAccess()
        ])
      )
      .subscribe(result => {
        this.roles = result[0] as Item[];
        this.geoScopes = result[1] as Scope[];
        this.orgTreeScopes = result[2] as Scope[];
        this.domainScope = result[3] as Scope;
      });
  }

  private getScopeIds(): number[] {
    if (this.isDomainAccess) {
      return [this.domainScope.id];
    }
    return this.orgTreeComponent.getSelectionIds().concat(this.geoTreeComponent.getSelectionIds());
  }

  getProfile(): Profile {
    let profile: Profile = new Profile();
    profile.scopesId = this.getScopeIds();
    if (this.profileForm.valid && profile.scopesId.length > 0) {
      if (this.userProfile) {
        profile.id = this.userProfile.id;
      }
      profile.name = this.profileForm.controls.profileNameFormControl.value;
      profile.roleId = this.profileForm.controls.selectedRole.value;
      profile.designatedArea = this.profileForm.controls.designatedArea.value;
      return profile;
    }

    return null;
  }

  private getProfileFormField(field: string) {
    return this.profileForm.get(field);
  }

  private setDomainAccessWhenSelectedSurgicalQaReviewer() {
    const domainAccessControl = this.getProfileFormField('isDomainAccess');

    const updateDomainAccessControl = () => {
      const designatedArea = this.getProfileFormField('designatedArea').value;

      if (qaReviewerRoleIds.includes(this.getProfileFormField('selectedRole').value) && designatedArea === InstanceArea.CHINA) {
        domainAccessControl.setValue(false);
        domainAccessControl.disable();
      } else if (this.getProfileFormField('selectedRole').value === surgicalQaReviewerSelectValue) {
        domainAccessControl.setValue(true);
        domainAccessControl.disable();
      } else {
        domainAccessControl.setValue(false);
        domainAccessControl.enable();
      }
    };

    this.getProfileFormField('selectedRole').valueChanges.subscribe(updateDomainAccessControl);
    this.getProfileFormField('designatedArea').valueChanges.subscribe(updateDomainAccessControl);
  }

  private setDomainAccessDisabledWhenSelectedChinaInstance() {
    const domainAccessControl = this.getProfileFormField('isDomainAccess');
    const updateDomainAccessControlForProfile = () => {
      const designatedArea = this.getProfileFormField('designatedArea').value;
      if (designatedArea === InstanceArea.CHINA) {
        domainAccessControl.setValue(false);
        domainAccessControl.disable();
        return;
      }

      if (!surgicalQaReviewerSelectValue === this.getProfileFormField('selectedRole').value) {
        domainAccessControl.enable();
        return;
      }
    };

    merge(this.getProfileFormField('selectedRole').valueChanges, this.getProfileFormField('designatedArea').valueChanges).subscribe(
      updateDomainAccessControlForProfile
    );
    updateDomainAccessControlForProfile();
  }

  isProfileNameLengthNotValid(): boolean {
    return !this.getProfileFormField('profileNameFormControl').valid;
  }

  private setCountrySelectionWhenQaReviewerAndChinaInstance() {
    const toggleChinaSelection = () => {
      const chinaNode = this.geoTreeComponent.treeControl.dataNodes.find(x => x.scope.id === chinaScopeId);
      if (chinaNode && this.geoTreeComponent.checklistSelection.isSelected(chinaNode) !== this.isGeoScopeDisabled) {
        this.geoTreeComponent.checklistSelection.select(chinaNode);
      }
    };

    merge(this.getProfileFormField('selectedRole').valueChanges, this.getProfileFormField('designatedArea').valueChanges).subscribe(() => {
      const isQaReviewer = qaReviewerRoleIds.includes(this.getProfileFormField('selectedRole').value);
      const isChinaInstance = this.getProfileFormField('designatedArea').value === InstanceArea.CHINA;
      this.isGeoScopeDisabled = isQaReviewer && isChinaInstance;

      toggleChinaSelection();
    });
  }
}
