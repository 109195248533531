import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { Scope, ScopeFlatNode } from '../../models/scope.model';

@Component({
  selector: 'stx-scope-tree',
  templateUrl: './scope-tree.component.html',
  styleUrls: ['./scope-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeTreeComponent implements OnInit {
  @Input() treeData: Scope[];
  @Input() isDomainAccess: boolean;
  @Input() selectedScope: Scope[];
  @Input() disabled: boolean;

  isSelectAll = false;

  nestedNodeMap = new Map<Scope, ScopeFlatNode>();

  treeControl: FlatTreeControl<ScopeFlatNode>;

  treeFlattener: MatTreeFlattener<Scope, ScopeFlatNode>;

  dataSource: MatTreeFlatDataSource<Scope, ScopeFlatNode>;

  checklistSelection = new SelectionModel<ScopeFlatNode>(true);

  getLevel = (node: ScopeFlatNode) => node.level;

  isExpandable = (node: ScopeFlatNode) => node.expandable;

  getChildren = (node: Scope): Scope[] => node.childrenScopes;

  hasChild = (_: number, nodeData: ScopeFlatNode) => nodeData.expandable;

  transformer = (node: Scope, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.scope === node ? existingNode : new ScopeFlatNode();
    flatNode.scope = node;
    flatNode.level = level;
    flatNode.expandable = node.childrenScopes && node.childrenScopes.length > 0;
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  ngOnInit(): void {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ScopeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    if (this.treeData) {
      this.dataSource.data = this.treeData;
    }
    if (this.selectedScope) {
      this.selectedScope.forEach(scope => {
        const node = this.treeControl.dataNodes.find(x => x.scope.id === scope.id);
        if (!!node && node.level === 0) {
          this.parentSelectionToggle(node);
        } else {
          this.childSelectionToggle(node);
        }
      });
    }
    if (this.checklistSelection.selected.length === this.flattenedData.length) {
      this.isSelectAll = true;
    }
  }

  // TOTO#14028 remove this hacky method
  get flattenedData(): ScopeFlatNode[] {
    return this.dataSource['_flattenedData'].value;
  }

  descendantsAllSelected(node: ScopeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    if (descendants.length > 0) {
      let selectedDescentants = descendants.filter(child => this.checklistSelection.isSelected(child));
      return this.getChildrenScopesFromTreeData(node).length === selectedDescentants.length;
    }

    return false;
  }

  descendantsPartiallySelected(node: ScopeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    let selectedDescentants = descendants.filter(child => this.checklistSelection.isSelected(child));

    return this.getChildrenScopesFromTreeData(node).length > selectedDescentants.length && selectedDescentants.length > 0;
  }

  parentSelectionToggle(node: ScopeFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    this.checkAllParentsSelection(node);
  }

  childSelectionToggle(node: ScopeFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: ScopeFlatNode): void {
    let parent: ScopeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: ScopeFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: ScopeFlatNode): ScopeFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  toggleSelectAll(): void {
    this.isSelectAll = !this.isSelectAll;
    if (this.isSelectAll) {
      this.checklistSelection.select(...this.flattenedData);
    } else {
      this.checklistSelection.deselect(...this.flattenedData);
    }
  }

  getSelectionIds(): number[] {
    let selectChildren = this.checklistSelection.selected.filter(childNode => childNode.scope.parentId);

    let selectedParents = this.checklistSelection.selected
      .filter(parentNode => !parentNode.scope.parentId)
      .filter(parentNode => this.shouldParentBeInScope(parentNode, selectChildren));

    let selectedParentsValueIds = selectedParents.map(node => node.scope.valueId);

    return selectChildren
      .filter(childNode => !selectedParentsValueIds.includes(childNode.scope.parentId))
      .map(childNode => childNode.scope.id)
      .concat(selectedParents.map(parentNode => parentNode.scope.id));
  }

  onSearchChange(searchTerm: string): void {
    const filteredData = this.treeData
      .filter(x => this.nodeMatches(x, searchTerm))
      .map(parentScope => {
        return { ...parentScope };
      });
    filteredData.forEach(scope => {
      scope.childrenScopes = this.caseInsensitiveContains(scope.value, searchTerm)
        ? scope.childrenScopes
        : scope.childrenScopes.filter(x => this.caseInsensitiveContains(x.value, searchTerm));
    });
    this.dataSource.data = filteredData;
  }

  private nodeMatches(scope: Scope, searchTerm: string): boolean {
    return (
      scope.childrenScopes.filter(y => this.caseInsensitiveContains(y.value, searchTerm)).length > 0 ||
      this.caseInsensitiveContains(scope.value, searchTerm)
    );
  }

  private caseInsensitiveContains(value: string, searchTerm: string): boolean {
    return value.toLowerCase().includes(searchTerm.toLowerCase());
  }

  private shouldParentBeInScope(node: ScopeFlatNode, selectChildren: ScopeFlatNode[]): boolean {
    return (
      this.checklistSelection.isSelected(node) &&
      this.getChildrenScopesFromTreeData(node).length ===
        selectChildren.filter(childNode => childNode.scope.parentId === node.scope.valueId).length
    );
  }

  private getChildrenScopesFromTreeData(node: ScopeFlatNode): Scope[] {
    return this.treeData.filter(scope => scope.id === node.scope.id).pop().childrenScopes;
  }
}
