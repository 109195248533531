<div class="form-row">
  <stx-audio-video
    [label]="label"
    [tooltip]="tooltip"
    [formMode]="formMode"
    [formGroup]="formGroup"
    [parentOrderName]="parentOrderNameToSet"
    [controlName]="parentOrderNameToSet"
    [print]="print"
  >
  </stx-audio-video>
</div>
<div class="form-row">
  <div>
    <p class="quote">{{ repetitionText }}</p>
    <ng-container *ngIf="!print && displayLanguageChoice">
      <p>
        <ng-container *ngFor="let language of languages; let first = first">
          <span *ngIf="!first">|</span>
          <button
            mat-button
            class="fs-12 language-select"
            [class.selected]="selectedLanguage === language"
            (click)="selectLanguage(language)"
          >
            {{ language }}
          </button>
        </ng-container>
      </p>
    </ng-container>
  </div>
</div>
