<h1>{{ 'patient.registration' | translate }}</h1>

<div class="page-section-container" *ngIf="patient">
  <stx-readonly-view-columns>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-view-section-header labelKey="ortho.general_patient_information"></stx-readonly-view-section-header>
      <stx-simple-readonly-field
        label="patient.record_number"
        [value]="[patient.recordNumberId, patient.recordNumberIdLoc]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="patient.patient" [value]="[name, nameLoc]"></stx-simple-readonly-field>
      <stx-simple-readonly-field label="patient.date_of_birth" [value]="patient.dateOfBirth"></stx-simple-readonly-field>
      <stx-simple-readonly-field
        [value]="'patient.date_of_birth_estimated' | if : patient.dateOfBirthEstimated | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.gender.view_label"
        [value]="patient.gender | dict : 'genders' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.race"
        [value]="patient.race | dict : 'races' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="partner.partner" [value]="patient.partner?.name"></stx-simple-readonly-field>
      <stx-readonly-field label="treatment_center.treatment_center">
        <stx-tc-link [treatmentCenter]="patient.treatmentCenter"></stx-tc-link>
      </stx-readonly-field>
      <stx-readonly-field label="address" *ngIf="addressItems.length > 0">
        <ng-container *ngFor="let item of addressItems">
          <span>{{ item }}</span>
          <br />
        </ng-container>
      </stx-readonly-field>
      <stx-simple-readonly-field
        label="patient.fields.address.phone"
        [value]="[patient?.address?.phone, patient?.address?.mobile]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="reports.parentOrGuardian"
        [value]="[guardianName, patient.guardianRelationship | dict : 'guardianRel' | async | translate]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.referer.view_label"
        [value]="patient.knowAboutSt | dict : 'knowAboutSt' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.referring_organization_name.view_label"
        [value]="patient.referringOrg"
      ></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-view-section-header labelKey="patient.family_history"></stx-readonly-view-section-header>
      <stx-simple-readonly-field
        label="patient.family.pregnancy_length.view_label"
        [value]="pregnancyLength | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.family.pregnancy_complications.view_label"
        [value]="patient.diagnosis?.pregnancyComplication | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.family.birth_complications.view_label"
        [value]="patient.diagnosis?.birthComplication | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.family.pregnancy_smoke.view_label"
        [value]="patient.diagnosis?.motherSmoked | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.family.alcohol.view_label"
        [value]="patient.diagnosis?.motherAlcohol | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.family.close_family_history.view_label"
        [value]="patient.diagnosis?.immedRelativeCleft | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.family.distant_family_history.view_label"
        [value]="patient.diagnosis?.distRelativeCleft | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.diagnosis.previous_surgery.view_label"
        [value]="[
          patient.diagnosis?.beforeSurgery | yesNo,
          patient.diagnosis?.previousSurgery | dict : 'previousSurgeryOptions' | async | translate
        ]"
      ></stx-simple-readonly-field>
    </ng-container>
    <ng-container *stxReadonlyViewColumn>
      <stx-readonly-view-section-header labelKey="patient.diagnosis"></stx-readonly-view-section-header>
      <stx-simple-readonly-field
        label="patient.fields.diagnosis.evaluation_date"
        [value]="patient.diagnosis?.evaluationDate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.weight"
        [value]="patient.diagnosis?.evaluationWeight"
        [suffix]="'unit.weight' | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="height"
        [value]="patient.diagnosis?.evaluationHeight"
        [suffix]="'unit.height' | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_lip_left"
        [value]="patient.diagnosis?.leftCleftLip | dict : 'cleftLipOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_lip_right"
        [value]="patient.diagnosis?.rightCleftLip | dict : 'cleftLipOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_alveolus_left"
        [value]="patient.diagnosis?.leftAlveolus | dict : 'cleftLipOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_alveolus_right"
        [value]="patient.diagnosis?.rightAlveolus | dict : 'cleftLipOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_hard_palate_left"
        [value]="patient.diagnosis?.leftHardCleftPalate | dict : 'cleftPalateOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_hard_palate_right"
        [value]="patient.diagnosis?.rightHardCleftPalate | dict : 'cleftPalateOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="surgical_qa_panel.diagnose_issue_type_soft_palate"
        [value]="patient.diagnosis?.softCleftPalate | dict : 'cleftPalateOptions' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field label="comments" [value]="patient.diagnosis?.comment"></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.diagnosis.deformities.view_label"
        [value]="patient.diagnosis?.otherCleft | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.diagnosis.velo_insuff.view_label"
        [value]="patient.diagnosis?.veloInsufficiency | dict : 'yesNoDontKnow' | async | translate"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="reports.filters.stats_type.abnormalieties"
        [value]="[
          'patient.heart' | if : patient.diagnosis?.heart | translate,
          'patient.urinary' | if : patient.diagnosis?.urinary | translate,
          'patient.eyes' | if : patient.diagnosis?.eyes | translate,
          'patient.nose' | if : patient.diagnosis?.nose | translate,
          'patient.ears' | if : patient.diagnosis?.ears | translate,
          'patient.limbs' | if : patient.diagnosis?.limbs | translate,
          'patient.fingerToes' | if : patient.diagnosis?.fingersToes | translate,
          'patient.skin' | if : patient.diagnosis?.skin | translate,
          'patient.tongue' | if : patient.diagnosis?.tongue | translate,
          'patient.skull' | if : patient.diagnosis?.skull | translate,
          'patient.mandible' | if : patient.diagnosis?.mandible | translate,
          'patient.diagnosis.speech.view_label' | if : patient.diagnosis?.speech | translate,
          'patient.growth' | if : patient.diagnosis?.growth | translate,
          'patient.mental' | if : patient.diagnosis?.mental | translate
        ]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.diagnosis.allergies.view_label"
        [value]="[patient.diagnosis?.allergies | dict : 'yesNoDontKnow' | async | translate, patient.diagnosis?.otherAllergies]"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.diagnosis.allergies_medication.view_label"
        [value]="patient.diagnosis?.medAllergies"
      ></stx-simple-readonly-field>
      <stx-simple-readonly-field
        label="patient.diagnosis.other_problems.view_label"
        [value]="patient.diagnosis?.otherProblems"
      ></stx-simple-readonly-field>
    </ng-container>
  </stx-readonly-view-columns>
</div>
