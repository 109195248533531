import { PartnerDictionary } from '@/src/app/features/partner/models/partner-dictionary-model';
import { Patient, PatientId } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { FormActionFlowControl } from '@/src/app/shared/components/base-treatment-form/form-action-flow-control.models';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionPromptComponent } from '@shared/components/action-prompt/action-prompt.component';
import { ActionPromptConfig, ActionPromptResult, isPromptConfirmed } from '@shared/components/action-prompt/action-prompt.model';
import { OnlinePatientReleaseForm, PatientDataForReleaseForm } from '@shared/components/patient-document-upload/patient-release-form.model';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { CountryDictionary, DistrictDictionary, ProvinceDictionary } from '@shared/models/geo.model';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { GeoService } from '@shared/services/geo.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { updateValueAndValidityForControl } from '@shared/validation/validation.utils';
import { PartnerService } from '@src/app/features/partner/partner.service';
import { PatientFormModel } from '@src/app/features/patient/components/patient-form/patient-form.model';
import {
  ModifyPatientPrecondition,
  preconditionEnumToUserMessages
} from '@src/app/features/patient/models/modify-patient-precondition.enum';
import { PatientDataService } from '@src/app/features/patient/services/patient-data.service';
import { PatientDictionaries, PatientDictionariesService } from '@src/app/features/patient/services/patient-dictionaries.service';
import { httpStatusPreconditionFailed } from '@utils/http.utils';
import { patientDetailsPath } from '@utils/routing.utils';
import { scrollToFirstInvalidField } from '@utils/scroll.utils';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { GlobalErrorHandlerService } from 'src/app/shared/services/global-error-handler.service';
import { PrfValidationService } from '@src/app/features/patient/services/prf-validation.service';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-patient-form',
  templateUrl: './patient-form.component.html'
})
export class PatientFormComponent extends BaseTreatmentFormComponent<Patient> implements OnDestroy {
  readonly partnerList$: Subject<PartnerDictionary[] | null>;
  readonly tcList$: Subject<TreatmentCenterDictionary[] | null>;
  readonly countryList$: Subject<CountryDictionary[] | null>;
  readonly provinceList$: Subject<ProvinceDictionary[] | null>;
  readonly districtList$: BehaviorSubject<DistrictDictionary[] | null>;
  readonly form: PatientFormModel;
  readonly addressFormGroup: UntypedFormGroup;
  readonly diagnosisFormGroup: UntypedFormGroup;
  dictionaries: PatientDictionaries;
  patientDataMatchesOnlineReleaseFormData: boolean | null = null;
  patientDataMatchesOfflineReleaseFormData: boolean | null = null;
  private _patientId?: PatientId;
  private _patientToCloneId?: number;
  private forceNextBackendCall: boolean = false;

  constructor(
    activatedRoute: ActivatedRoute,
    cd: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    elementRef: ElementRef,
    geoService: GeoService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    partnerService: PartnerService,
    private readonly patientDataService: PatientDataService,
    patientDictionariesService: PatientDictionariesService,
    patientService: PatientService,
    router: Router,
    snackBarService: SnackBarService,
    spinnerService: SpinnerService,
    wsHelper: WsHelperService,
    zone: NgZone,
    private prfValidationService: PrfValidationService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      cd,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.partnerList$ = new BehaviorSubject<TreatmentCenterDictionary[]>(null);
    this.tcList$ = new BehaviorSubject<TreatmentCenterDictionary[]>(null);
    this.countryList$ = new BehaviorSubject<CountryDictionary[]>(null);
    this.provinceList$ = new BehaviorSubject<ProvinceDictionary[]>(null);
    this.districtList$ = new BehaviorSubject<DistrictDictionary[]>(null);
    this.form = new PatientFormModel(this.partnerList$, this.tcList$, this.countryList$, this.provinceList$, this.districtList$);
    this.formGroup = this.form.formGroup;
    this.addressFormGroup = this.form.addressFormGroup;
    this.diagnosisFormGroup = this.form.diagnosisFormGroup;
    this.configureForm(this.formGroup, {
      controlNamesForFullValidation: PatientFormModel.fieldsRequiredForSave,
      nestedPartialValidationConfigs: {
        diagnosis: { controlNamesForFullValidation: ['evaluationDate'] }
      }
    });
    this.connectDictionaries(patientDictionariesService, partnerService, geoService);
    this.connectPatientReleaseForm();
    this.connectPatient();

    this.subSink.sink = this.prfValidationService.getPrfValidation().subscribe(() => {
      this.patientDataForReleaseForm();
    });
  }

  @Input()
  set initialPatientData(initialPatientData: Patient | null) {
    if (!initialPatientData) {
      return;
    }
    this._patientToCloneId = initialPatientData.patientToCloneId;
    if (!!this._patientToCloneId) {
      delete initialPatientData?.diagnosis?.evaluationDate;
      delete initialPatientData?.treatmentCenterId;
      delete initialPatientData?.partnerId;
    }
    this.form.data = initialPatientData;
  }

  get isReleaseFormCloned(): boolean {
    return this._patientToCloneId !== undefined && (!!this.form.onlineReleaseForm.value || !!this.form.offlineReleaseForm.value);
  }

  get areDictionariesLoaded(): boolean {
    return !!this.dictionaries;
  }

  readonly patientDataForReleaseForm: () => Observable<PatientDataForReleaseForm> | null = () => {
    const valid = this.form.patientDataForReleaseFormControls.every(control => control.valid);
    if (valid) {
      return this.form.patientDataForReleaseForm;
    } else {
      this.form.patientDataForReleaseFormControls.forEach(control => control.markAllAsTouched());
      scrollToFirstInvalidField(this.elementRef, this.zone);
      return null;
    }
  };

  protected override afterSubmitValidationAsync(): Observable<FormActionFlowControl> {
    return super.afterSubmitValidationAsync().pipe(mergeMap(() => this.preSaveOrSubmitChecks()));
  }

  protected override afterSaveValidationAsync(): Observable<FormActionFlowControl> {
    return super.afterSaveValidationAsync().pipe(mergeMap(() => this.preSaveOrSubmitChecks()));
  }

  ngOnDestroy() {
    this.form.ngOnDestroy();
    super.ngOnDestroy();
  }

  protected callDelete(id: number): Observable<void> {
    return this.patientService.deletePatientById(id);
  }

  protected callSave(data: Patient): Observable<Patient> {
    const force = this.forceNextBackendCall;
    this.forceNextBackendCall = false;
    return this.patientService.draftPatient(data, force);
  }

  protected callSubmit(data: Patient): Observable<Patient> {
    const force = this.forceNextBackendCall;
    this.forceNextBackendCall = false;
    return this.patientService.submitPatient(data, force);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.patientService.unlockPatientById(id);
  }

  protected getEditRoute(treatmentId: number): string {
    return patientDetailsPath(treatmentId);
  }

  protected getPatientId(): number {
    return undefined;
  }

  protected getTreatmentDataToSave(): Patient {
    return {
      id: this._patientId,
      patientToCloneId: this._patientToCloneId,
      ...this.form.data
    };
  }

  protected getTreatmentId(): TreatmentId {
    return this._patientId;
  }

  protected getViewRoute(treatmentId: number): string {
    return patientDetailsPath(treatmentId);
  }

  protected setTreatmentData(data: Patient): void {
    this.patientDataService.patient$.next(data);
  }

  protected handleSubmitError(error: HttpErrorResponse) {
    if ((error as HttpErrorResponse)?.status === httpStatusPreconditionFailed) {
      this.handleBackendPreconditionsNotConfirmed((error as HttpErrorResponse).error, true);
    } else {
      this.globalErrorHandlerService.handleErrorAndInformUserWithAlertDialog(error);
    }
  }

  protected handleSaveError(error: HttpErrorResponse) {
    if ((error as HttpErrorResponse)?.status === httpStatusPreconditionFailed) {
      this.handleBackendPreconditionsNotConfirmed((error as HttpErrorResponse).error, false);
    } else {
      this.globalErrorHandlerService.handleErrorAndInformUserWithAlertDialog(error);
    }
  }

  get isMobileScreenSize(): boolean {
    const mobileBreakPointSize: number = 768;

    return window.innerWidth < mobileBreakPointSize;
  }

  private connectDictionaries(
    patientDictionariesService: PatientDictionariesService,
    partnerService: PartnerService,
    geoService: GeoService
  ) {
    partnerService.clearUserPartnersCache();
    this.subSink.sink = patientDictionariesService.dictionaries$.subscribe(d => {
      this.dictionaries = d;
    });
    this.wsHelper.pipeToSubject(partnerService.getUserPartners(), this.partnerList$);
    this.subSink.sink = this.form.partner$.subscribe(partner => {
      this.tcList$.next(partner?.treatmentCenters || null);
    });
    this.subSink.sink = geoService.getCountryDictionaries.subscribe(countries => {
      this.countryList$.next(countries || null);
    });
    this.subSink.sink = this.form.country$.subscribe(country => {
      this.provinceList$.next(country?.provinces || null);
    });
    this.subSink.sink = this.form.province$.subscribe(province => {
      this.districtList$.next(province?.districts || null);
      updateValueAndValidityForControl(this.form.addressControls.districtId);
    });
    this.subSink.sink = this.form.country$.subscribe(country => {
      this.setDefaultValuesInControlsDependentOnCountry(country);
    });
  }

  private setDefaultValuesInControlsDependentOnCountry(country: CountryDictionary) {
    const countryIsSelected = !!country;

    if (countryIsSelected) {
      this.form.districtRequired = country.districtIdReq;
      updateValueAndValidityForControl(this.form.addressControls.districtId);
      const raceControl = this.form.mainControls.race;
      const isRaceChangedByUser = raceControl.dirty;
      if (!isRaceChangedByUser) {
        const matchingRace = this.dictionaries.races.find(race => race.value === country.raceId);
        if (!!matchingRace) {
          raceControl.setValue(matchingRace.value);
        }
      }
    }
  }

  private connectPatientReleaseForm() {
    this.subSink.sink = combineLatest([this.form.patientDataForReleaseForm, this.form.onlineReleaseForm.valueChanges]).subscribe(
      ([patientDataForReleaseForm, onlineReleaseForm]) => {
        const prfExists = !!onlineReleaseForm;
        const matches = isEqual(patientDataForReleaseForm, (onlineReleaseForm as OnlinePatientReleaseForm)?.patientData);
        this.patientDataMatchesOnlineReleaseFormData = prfExists ? matches : null;
      }
    );
    let patientDataWhenFileUploaded = null;
    this.subSink.sink = this.form.patientDataForReleaseForm.subscribe(patientDataForReleaseForm => {
      if (!isEqual(patientDataForReleaseForm, patientDataWhenFileUploaded)) {
        this.patientDataMatchesOfflineReleaseFormData = false;
      }
    });
    this.subSink.sink = this.form.offlineReleaseForm.valueChanges.subscribe(file => {
      const isNewFile = !!file;
      if (isNewFile) {
        patientDataWhenFileUploaded = this.form.patientDataForReleaseForm.value;
        this.patientDataMatchesOfflineReleaseFormData = true;
      } else {
        this.patientDataMatchesOfflineReleaseFormData = null;
      }
    });
  }

  private connectPatient() {
    this.subSink.sink = this.patientDataService.patient$.subscribe(patient => {
      this._patientId = patient?.id;
      this._patientToCloneId = null;
      this.form.data = patient;
    });
  }

  private handleBackendPreconditionsNotConfirmed(failedPreconditions: ModifyPatientPrecondition[], submit: boolean) {
    this.getUserDecision({
      promptHeader: 'patient.dialog.confirmation.header',
      promptBody: preconditionEnumToUserMessages(failedPreconditions),
      submitButtonText: 'patient.online_release_form.proceed',
      cancelButtonText: 'patient.online_release_form.review'
    })
      .pipe(filter(promptResult => isPromptConfirmed(promptResult)))
      .subscribe(() => {
        this.forceNextBackendCall = true;
        if (submit) {
          this.subSink.sink = this.onSubmitValidationSuccess().subscribe();
        } else {
          this.subSink.sink = this.onSaveValidationSuccess().subscribe();
        }
      });
  }

  private preSaveOrSubmitChecks(): Observable<FormActionFlowControl> {
    const hasOnlineRf = !!this.form.onlineReleaseForm.value;
    const hasOfflineRf = !!this.form.offlineReleaseForm.value;
    if (
      (hasOnlineRf && this.patientDataMatchesOnlineReleaseFormData === false) ||
      (hasOfflineRf && this.patientDataMatchesOfflineReleaseFormData === false)
    ) {
      return this.confirmPrfDoesNotMatch();
    }
    return of({ proceed: true });
  }

  private confirmPrfDoesNotMatch(): Observable<FormActionFlowControl> {
    const confirmConfig = {
      promptHeader: 'patient.release_form',
      promptBody: ['patient.offline_release_form.warning'],
      submitButtonText: 'patient.online_release_form.proceed',
      cancelButtonText: 'patient.online_release_form.review'
    };
    return this.getUserDecision(confirmConfig).pipe(
      map(promptResult => {
        return { proceed: isPromptConfirmed(promptResult) };
      })
    );
  }

  private getUserDecision(confirmConfig: ActionPromptConfig): Observable<ActionPromptResult | null> {
    const dialogRef = this.dialog.open(ActionPromptComponent, {
      autoFocus: false,
      data: confirmConfig
    });
    return dialogRef.afterClosed();
  }
}
