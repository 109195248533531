import { DictionaryTranslation } from '../features/patient/models/patient.model';

export const chinaCountryId = 1077;
export const indiaCountryId = 1084;
export const philippinesCountryId = 1095;
export const maleGender = 'M';
export const defaultGuardianRelation = 1;
export const defaultKnowByST = 1;
export const indiaCountryName = 'India';
export const surgicalQaReviewerSelectValue = 7;
export const qaReviewerRoleIds = [7, 8, 9];
export const chinaScopeId = 83;

export const defaultDebounceTime = 300;

export const abnormalities: DictionaryTranslation[] = [
  { value: false, label: 'heart' },
  { value: false, label: 'fingersToes' },
  { value: false, label: 'mental' },
  { value: false, label: 'urinary' },
  { value: false, label: 'mandible' },
  { value: false, label: 'growth' },
  { value: false, label: 'eyes' },
  { value: false, label: 'tongue' },
  { value: false, label: 'skin' },
  { value: false, label: 'nose' },
  { value: false, label: 'skull' },
  { value: false, label: 'speech' },
  { value: false, label: 'limbs' },
  { value: false, label: 'ears' }
];
