import { Injectable } from '@angular/core';
import { noop, Observable, of } from 'rxjs';
import { LoginCredentials } from '../models/login-credentials.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user/user.model';
import { catchError, map, tap } from 'rxjs/operators';
import { PartnerService } from '../../partner/partner.service';
import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { isPermitted } from '@/src/app/core/permissions/permission.util';
import { TempLangService } from '@shared/services/localisation/temp-lang.service';
import { RoleEnum } from '@src/app/features/authentication/helpers/role.enum';
import { LoginTokenCredentials } from '@src/app/features/authentication/models/login-token-credentaials.model';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { Router } from '@angular/router';
import { getLanguageByShortcut } from '@utils/language.utils';
import { ConfigurationService } from '@/src/app/shared/services/configuration.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';

//TODO#9896 - Notice for refactor
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private readonly partnerService: PartnerService,
    private readonly tempLangService: TempLangService,
    private readonly translateService: TranslateService,
    private readonly spinnerService: SpinnerService,
    private readonly router: Router,
    private readonly configService: ConfigurationService,
    private currentUserService: CurrentUserService,
    private passwordExpiryService: PasswordExpiryService
  ) {}

  private static readonly credentialsExpiredErrorKey = 'credentials_expired';

  loginWithTokenCredentials(credentials: LoginTokenCredentials): Observable<any> {
    const dataToSend = new FormData();
    dataToSend.append('token', credentials.token);
    dataToSend.append('profileId', credentials.profileId.toString());
    return this.login(dataToSend);
  }

  loginWithUserCredentials(credentials: LoginCredentials): Observable<any> {
    const dataToSend = new FormData();
    dataToSend.append('email', credentials.email);
    dataToSend.append('password', credentials.password);
    return this.login(dataToSend);
  }

  login(dataToSend: FormData): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrl + '/login', dataToSend);
  }

  logout(): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/logout', { responseType: 'text' });
  }

  generateToken(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/token');
  }

  cleanUpLogoutUser() {
    this.currentUserService.setCurrentUser(null);
    this.partnerService.clearUserPartnersCache();
    this.tempLangService.clearTempLanguage();
  }

  sendResetPasswordLink(email: string): Observable<any> {
    const dataToSend = {
      mail: email
    };
    return this.httpClient.post<any>(environment.apiUrl + '/reset-password', dataToSend);
  }

  sendActivationLink(email: string | undefined): Observable<any> {
    if (!email) {
      throw new Error('Unable to send activation link due to missing user email.');
    }
    const dataToSend = {
      mail: email
    };
    return this.httpClient.post<any>(environment.apiUrl + '/activate-account', dataToSend);
  }

  resetPassword(password: string, resetKey: string): Observable<any> {
    const dataToSend = {
      password,
      resetKey
    };
    return this.httpClient.post<any>(environment.apiUrl + '/confirm-reset', dataToSend);
  }

  getUserAndManageTempLang(): Observable<User> {
    return this.httpClient.get<User>(environment.apiUrl + '/users/currentUser').pipe(
      tap({
        next: noop,
        error: error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.tempLangService.clearTempLanguage();
          }
        }
      }),
      map(user => {
        const tempLang = this.tempLangService.getTempLanguage();
        if (!!tempLang) {
          user.tempLang = tempLang;
        }
        return user;
      })
    );
  }

  changePassword(current: string, newPass: string): Observable<any> {
    const payload = {
      currentPassword: current,
      newPassword: newPass
    };
    return this.httpClient.patch<any>(environment.apiUrl + '/change-password', payload);
  }

  //TODO#9896 - Notice for refactor
  isAuthenticated(): Observable<boolean> {
    return this.getUserAndManageTempLang().pipe(
      catchError(() => {
        return of(null);
      }),
      map(user => {
        if (user) {
          this.currentUserService.setCurrentUser(user);
          return true;
        } else {
          this.currentUserService.setCurrentUser(null);
          return false;
        }
      })
    );
  }

  checkUserPermission(permission: PermissionEnum): boolean {
    const currentUser = this.currentUserService.getCurrentUser();
    return (
      currentUser &&
      isPermitted(
        currentUser.permissions.map(x => x.name),
        permission
      )
    );
  }

  checkUserRole(role: RoleEnum): boolean {
    const currentUser: User = this.currentUserService.getCurrentUser();
    return currentUser && currentUser.activeProfile.role.name === role;
  }

  loginWithDefaultCredentials(credentials: LoginCredentials, onError: () => void): Observable<any> {
    return this.defaultLoginFlow(this.loginWithUserCredentials(credentials), onError);
  }

  loginWithToken(credentials: LoginTokenCredentials, onError: () => void): Observable<any> {
    return this.defaultLoginFlow(this.loginWithTokenCredentials(credentials), onError);
  }

  defaultLoginFlow(loginObservable: Observable<any>, onError: () => void) {
    return loginObservable.pipe(
      tap({
        next: () => {
          this.getUserAndManageTempLang().subscribe({
            next: user => {
              if (!user.activeProfile) {
                this.setUpAfterSuccessLogin(user);
                this.loginForMultiProfile();
                return;
              }

              this.loginForSingleProfile(user);
            }
          });
        },
        error: response => {
          if (this.isUserCredentialsExpired(response)) {
            this.router.navigate(['reset-password'], { queryParams: { key: response.error.resetKey } });
            this.passwordExpiryService.setShowExpiredPasswordMessage(true);
          }

          onError();
        }
      })
    );
  }

  private isUserCredentialsExpired(response: HttpErrorResponse): boolean {
    return response.error.errorKey === AuthService.credentialsExpiredErrorKey;
  }

  loginForSingleProfile(user: User) {
    return this.configService.getCurrentInstanceArea().then(currentInstanceArea => {
      if (user.activeProfile.designatedArea === currentInstanceArea.valueOf()) {
        this.setUpAfterSuccessLogin(user);
        this.loginToCurrentApp(user);
      } else {
        this.redirectToRegional();
      }
    });
  }

  setUpAfterSuccessLogin(user: User) {
    this.currentUserService.setCurrentUser(user);
    this.translateService.use(getLanguageByShortcut(user.lang).name);
    this.spinnerService.removeTask();
  }

  loginToCurrentApp(user: User) {
    const roleNames = user.permissions.map(p => p.name);
    if (roleNames.includes(PermissionEnum.VIEW_DASHBOARD_MAIN) || roleNames.includes(PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO)) {
      this.router.navigateByUrl('/');
    } else if (roleNames.includes(PermissionEnum.VIEW_DASHBOARD_REVIEW_SURGICAL)) {
      this.router.navigateByUrl('/qa-surgical');
    }
  }

  redirectToRegional() {
    this.generateToken().subscribe(response => {
      this.logout().subscribe();
      window.location.href = response.redirectUrl;
    });
  }

  loginForMultiProfile() {
    this.router.navigateByUrl('/select-profile');
  }
}
