import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormMode, yesNoShort } from 'src/app/utils/form.utils';
import { DictionaryTranslation } from '../../../patient/models/patient.model';
import { ReviewUpdate } from '../models/review-update.model';
import { requiredIfValidator } from '@shared/validation/validators';
import { updateValueAndValidityForAllControls } from '@shared/validation/validation.utils';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'stx-grading-form',
  templateUrl: './grading-form.component.html',
  styleUrls: ['./grading-form.component.scss']
})
export class GradingFormComponent extends BaseComponent implements OnInit {
  @Input() numberOfPendingCasesForReviewer: number;
  @Input() preGrades: DictionaryTranslation[];
  @Input() postGrades: DictionaryTranslation[];
  @Input() failPassGrades: DictionaryTranslation[];
  @Input() isPalateTreatment: boolean;
  @Input() gradingTitle: string;
  @Input() submitSurgicalReviewWsCall: (x: ReviewUpdate) => Observable<void>;
  readonly formMode = FormMode;
  readonly yesNo = yesNoShort;
  readonly defaultPreGradeForPalates = 3;

  gradingFormGroup: UntypedFormGroup;
  displayValidationError = false;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.gradingFormGroup = this.createInitialForm();
    this.watchChangesOfAlertSmileTrainCheckbox();
    this.watchChangesOfGrades();
  }

  onSurgicalReviewSubmitted: AsyncButtonClickAction = () => {
    Object.keys(this.gradingFormGroup.controls).forEach(controlName => {
      this.gradingFormGroup.get(controlName).markAsTouched();
    });

    if (!this.checkIfFormIsValid(this.gradingFormGroup) || !this.gradingFormGroup.valid) {
      this.displayValidationError = true;
      return of(null);
    }
    this.displayValidationError = false;
    const reviewUpdate: ReviewUpdate = this.gradingFormGroup.value;
    if (this.isPalateTreatment) {
      reviewUpdate.preGrade = this.defaultPreGradeForPalates;
    }
    return this.submitSurgicalReviewWsCall(reviewUpdate).pipe(
      tap(() => {
        this.gradingFormGroup.reset();
        this.gradingFormGroup.get('qaPhotoQuality').setValue(1);
      })
    );
  };

  private createInitialForm() {
    return this.formBuilder.group({
      preGrade: [null],
      postGrade: [null],
      qaPhotoQuality: 1,
      alertSmileTrain: false,
      note: [null, [requiredIfValidator(() => this.isAlertSmileTrainSelected)]]
    });
  }

  private checkIfFormIsValid(form: UntypedFormGroup): boolean {
    if (form.value.qaPhotoQuality === 0) {
      return true;
    }
    return this.checkIfScoringIsProvided(form) || this.checkIfNoteProvidedWhenScoringProvidedAndAlertSmileTrainCheckboxChecked(form);
  }

  private checkIfScoringIsProvided(form: UntypedFormGroup): boolean {
    if (!this.isPalateTreatment && !form.value.alertSmileTrain) {
      return form.value.preGrade && form.value.postGrade;
    }

    if (this.isPalateTreatment && !form.value.alertSmileTrain) {
      return form.value.postGrade;
    }

    return false;
  }

  private checkIfNoteProvidedWhenScoringProvidedAndAlertSmileTrainCheckboxChecked(form: UntypedFormGroup) {
    const validityCondition = form.value.postGrade && form.value.note && form.value.note !== '';
    if (!this.isPalateTreatment && form.value.alertSmileTrain) {
      return validityCondition && form.value.preGrade;
    }

    if (this.isPalateTreatment && form.value.alertSmileTrain) {
      return validityCondition;
    }

    return false;
  }

  get isAlertSmileTrainSelected(): boolean {
    return !!this.gradingFormGroup.get('alertSmileTrain').value;
  }

  get isPhotoQualityRequired(): boolean {
    return (
      this.displayValidationError &&
      this.gradingFormGroup.value.qaPhotoQuality === 1 &&
      !(this.gradingFormGroup.value.preGrade && this.gradingFormGroup.value.postGrade)
    );
  }

  private watchChangesOfAlertSmileTrainCheckbox(): void {
    this.subSink.sink = this.gradingFormGroup.get('alertSmileTrain').valueChanges.subscribe(value => {
      updateValueAndValidityForAllControls(this.gradingFormGroup);
    });
  }

  private watchChangesOfGrades(): void {
    this.subSink.sink = this.gradingFormGroup.get('preGrade').valueChanges.subscribe(() => {
      updateValueAndValidityForAllControls(this.gradingFormGroup);
    });
    this.subSink.sink = this.gradingFormGroup.get('postGrade').valueChanges.subscribe(() => {
      updateValueAndValidityForAllControls(this.gradingFormGroup);
    });
  }
}
