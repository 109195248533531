import { Component, Input } from '@angular/core';
import { MediaResource } from '@shared/models/media-resource.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { OrthognathicTreatment } from '@src/app/features/orthognathic/orthognathic-treatment.model';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';
import { Patient } from '@src/app/features/patient/models/patient.model';

@Component({
  selector: 'stx-orthognatic-treatment-readonly',
  templateUrl: './orthognatic-treatment-readonly.component.html',
  providers: [dictionaryFromMapProvider(undefined, s => null)]
})
export class OrthognaticTreatmentReadonlyComponent {
  @Input() treatment: OrthognathicTreatment;
  @Input() patient: Patient;

  get fileUrl(): string | null {
    const mediaUrls: MediaUrl[] = this.findMedia(ParentOrderName.ORTHOGNATHIC)?.urlList || [];
    return mediaUrls.find(media => !!media.url && media.mediaFileAvailable)?.url;
  }

  private findMedia(parentOrderName: ParentOrderName): MediaResource | null {
    return this.treatment?.mediaResources?.find(mediaResource => mediaResource.parentOrderName === parentOrderName);
  }
}
