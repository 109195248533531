import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageDialogData } from '@shared/components/message-dialog/message-dialog-data.model';
import { MessageDialogComponent } from '@shared/components/message-dialog/message-dialog.component';
import { RequestIssues } from '@shared/models/request-issues.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/features/authentication/services/auth.service';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { User } from 'src/app/shared/models/user/user.model';
import { GlobalErrorHandlerService } from 'src/app/shared/services/global-error-handler.service';
import { languages } from 'src/app/utils/language.utils';
import { PersonalData, Profile, UserData } from '../../models/user-data.model';
import { UserService } from '../../services/user.service';
import { UserPersonalDataComponent } from '../user-personal-data/user-personal-data.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'stx-user',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent extends BaseComponent implements OnInit {
  user: User;
  selectedProfile: Profile;
  isProfileReadonly = false;
  isNewUser = true;
  errorKeys: string[] = [];
  @ViewChild(UserPersonalDataComponent, { static: true }) personalDataComponent: UserPersonalDataComponent;
  @ViewChild(UserProfileComponent) profileComponent: UserProfileComponent;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private globalErrorHandler: GlobalErrorHandlerService,
    private dialog: MatDialog,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.getUserFromParams();
  }

  private getUserFromParams(): void {
    this.subSink.sink = this.route.params.subscribe({
      next: params => {
        if (params.id) {
          this.wsHelperService.call(this.userService.getUserById(params.id), { redirectOn404StatusCode: true }).subscribe(user => {
            this.user = user;
            this.isNewUser = false;
            this.isProfileReadonly = true;
            this.cd.detectChanges();
          });
        }
      }
    });
  }

  get showDeleteIcon(): boolean {
    return this.user.profiles.length > 1;
  }

  editProfile(profile: Profile): void {
    this.selectedProfile = profile;
    this.isProfileReadonly = false;
    this.cd.detectChanges();
  }

  deleteProfile(profile: Profile): void {
    if (this.user.profiles.length > 1) {
      const index = this.user.profiles.findIndex(x => x.id === profile.id);
      this.user.profiles.splice(index, 1);
    }
  }

  addProfile(): void {
    this.isProfileReadonly = false;
    this.selectedProfile = null;
  }

  initiateSave: (sendLink?: boolean) => AsyncButtonClickAction = (sendLink?: boolean) => () => {
    let userData: UserData;
    const profile = this.isProfileReadonly ? null : this.profileComponent.getProfile();
    const personalData = this.personalDataComponent.getValidPersonalData();

    if (!this.canBeSaved(personalData, profile)) {
      return of(null);
    }

    userData = this.createUser(personalData, profile);

    if (this.user) {
      return this.saveUserAndSendActivationLink(userData, sendLink, personalData);
    } else {
      return this.handleUserDoesNotExist(userData, sendLink, personalData);
    }
  };

  private saveUserAndSendActivationLink(userData: UserData, sendLink: boolean, personalData: PersonalData): Observable<User> {
    return this.userService.updateUser(userData).pipe(
      tap({
        next: (user: User) => {
          this.isProfileReadonly = true;
          this.user = user;
          this.cd.detectChanges();

          if (sendLink) {
            this.sendActivationLink(personalData);
          }
        },
        error: err => {
          if (UserPageComponent.isEditingForbiddenRole(err)) {
            this.displayUserRolesForbiddenDialog(err.error);
          } else {
            this.globalErrorHandler.handleErrorAndInformUser(err);
          }
        }
      })
    );
  }

  private handleUserDoesNotExist(userData: UserData, sendLink: boolean, personalData: PersonalData): Observable<User> {
    return this.userService.saveUser(userData).pipe(
      tap({
        next: (user: User) => {
          this.router.navigateByUrl(`/users/${user.id}`);
          if (sendLink) {
            this.sendActivationLink(personalData);
          }
        },
        error: err => {
          if (UserPageComponent.isEditingForbiddenRole(err)) {
            this.displayUserRolesForbiddenDialog(err.error);
          } else {
            this.globalErrorHandler.handleErrorAndInformUser(err);
          }
        }
      })
    );
  }

  private static isEditingForbiddenRole(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 400 && error.error.showIssuesToUser === true;
  }

  private displayUserRolesForbiddenDialog(requestIssues: RequestIssues) {
    this.dialog.open(MessageDialogComponent, {
      data: { translatedMessages: requestIssues.translatedIssues, noticeType: 'error' } as MessageDialogData
    });
  }

  sendActivationLink(personalData: PersonalData): void {
    this.errorKeys.splice(
      this.errorKeys.findIndex(x => x === 'error.inactive_user'),
      1
    );
    if (personalData.enabled) {
      this.wsHelperService.call(this.authService.sendActivationLink(this.personalDataComponent.userEmail)).subscribe();
    } else {
      this.errorKeys.push('error.inactive_user');
      this.cd.detectChanges();
    }
  }

  canBeSaved(personalData: PersonalData, profile: Profile): boolean {
    this.errorKeys.splice(
      this.errorKeys.findIndex(x => x === 'error.incomplete'),
      1
    );
    if (personalData && (profile || this.isProfileReadonly)) {
      return true;
    } else {
      this.errorKeys.push('error.incomplete');
    }
    return false;
  }

  createUser(personalData: PersonalData, profile: Profile): UserData {
    const userToSave: UserData = new UserData();
    if (this.user) {
      userToSave.id = this.user.id;
      userToSave.profiles = this.convertProfilesToSend(this.user.profiles);
      if (profile) {
        if (profile.id) {
          const indexToReplace = userToSave.profiles.findIndex(x => x.id === profile.id);
          userToSave.profiles[indexToReplace] = profile;
        } else {
          userToSave.profiles.push(profile);
        }
      }
    } else {
      userToSave.email = personalData.email;
      userToSave.profiles = [profile];
    }
    userToSave.firstName = personalData.firstName;
    userToSave.lastName = personalData.lastName;
    userToSave.middleName = personalData.middleName;
    userToSave.lang = languages.find(language => language.name === personalData.lang).shortcut;
    userToSave.enabled = personalData.enabled;

    return userToSave;
  }

  convertProfilesToSend(profiles: Profile[]): Profile[] {
    const userProfilesClone = [...profiles];

    userProfilesClone.forEach(profile => {
      if (!!profile.scopes && profile.scopes.length > 0) {
        profile.scopesId = profile.scopes.map(x => x.id);
      }
      if (profile.role) {
        profile.roleId = profile.role.id;
      }
    });

    return userProfilesClone;
  }
}
