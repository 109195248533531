import { getSurgicalPhotos } from '@/src/app/features/surgical/utils/surgical.utils';
import { TreatmentCenterDictionary } from '@/src/app/shared/models/treatment-center.model';
import { dateFormat } from '@/src/app/utils/date.utils';
import { Component, Input } from '@angular/core';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MediaSet } from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';
import { BaseReadonlyViewComponent } from '@shared/modules/readonly-commons/utils/base-readonly-view.component';
import { dictionaryFromMapProvider } from '@shared/services/dictionary-provider';
import { MediaUrl } from '@src/app/features/media/models/media-url.model';
import { SurgicalIntervention } from '@src/app/features/surgical/models/surgical-intervention.model';
import { SurgicalService } from '@src/app/features/surgical/surgical.service';
import { LocalNamePipe } from '@shared/pipes/local-name.pipe';
import { Patient } from '../../../patient/models/patient.model';

@Component({
  selector: 'stx-surgical-intervention-readonly',
  templateUrl: './surgical-intervention-readonly.component.html',
  providers: [dictionaryFromMapProvider(SurgicalService, s => s.getSurgeryStaticDictionaries()), LocalNamePipe]
})
export class SurgicalInterventionReadonlyComponent extends BaseReadonlyViewComponent<SurgicalIntervention> {
  @Input() patient: Patient;
  readonly dateFormat = dateFormat;

  constructor(private localNamePipe: LocalNamePipe) {
    super();
  }

  photos: MediaSet[] = [];

  indiaDeclaration: MediaUrl;

  preAnesthesiaMedia: MediaUrl;
  intraAnesthesiaMedia: MediaUrl;
  postAnesthesiaMedia: MediaUrl;

  get practitionersNames(): string[] {
    return this.treatment?.practitioners?.map(p => this.localNamePipe.transform(p.name, p.nameLoc)) || [];
  }

  get treatmentCenter(): TreatmentCenterDictionary | null {
    return this.treatment?.treatmentCenter;
  }

  get displayChinaSupportOptions(): boolean {
    return (
      !!this.treatment &&
      !!this.treatmentCenter &&
      (!!this.treatment.chinaSupport ||
        !!this.treatment.chinaSupportMigrant ||
        !!this.treatment.chinaSupportOrphan ||
        !!this.treatment.chinaSupportRegionNofund) &&
      this.treatmentCenter.countryId === this.chinaCountryId &&
      this.treatmentCenter.costSharePrimaryNotcovered &&
      (this.treatment.primaryLipNoseUnilateral || this.treatment.primaryLipNoseBilateral || this.treatment.primaryCleftPalate)
    );
  }

  protected afterTreatmentSet(): void {
    this.photos = getSurgicalPhotos(this.treatment);
    this.setIndiaDeclarationForm();
    this.setAnesthesiaMedia();
  }

  private getMediaWithParentOrder(anesthesiaParentOrderNameToLookFor: ParentOrderName): MediaUrl {
    return this.treatment?.mediaResources.find(resource => resource.parentOrderName === anesthesiaParentOrderNameToLookFor)?.urlList[0];
  }

  private setIndiaDeclarationForm(): void {
    this.indiaDeclaration = this.getMediaWithParentOrder(ParentOrderName.INDIA_DECLARATION_FORM);
  }

  private setAnesthesiaMedia(): void {
    this.preAnesthesiaMedia = this.getMediaWithParentOrder(ParentOrderName.ANESTHESIA_PRE_OPERATIVE);
    this.intraAnesthesiaMedia = this.getMediaWithParentOrder(ParentOrderName.ANESTHESIA_INTRA_OPERATIVE);
    this.postAnesthesiaMedia = this.getMediaWithParentOrder(ParentOrderName.ANESTHESIA_POST_OPERATIVE);
  }
}
