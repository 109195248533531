import packageInfo from '../../package.json';
export const environment = {
  production: false,
  sentry_dsn: null,
  apiUrl: '/api',
  mediaUploadUrl: '/api/media/upload',
  googleAnalyticsMeasurementId: 'G-GPSWKSYRYL',
  passwordExpiryPopupThreshold: 45,
  passwordExpiryNotificationThreshold: 60,
  version: packageInfo.version,
  buildTimestamp: '2025-04-01T12:14:35.649Z'
};
