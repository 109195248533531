import { EducationalResourcesService } from '@/src/app/features/educational-resources/educational-resources.service';
import { MedicalMeeting, MedicalMeetingCategory } from '@/src/app/features/educational-resources/models/medical-meeting.model';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { cleftPublicationsFullRoute, educationAndResearchFullRoute } from '@/src/app/utils/routing.utils';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { covidResources, saqProtocolsResources } from '@/src/app/features/educational-resources/utils/educational-resources.utils';
import { ResourceSection } from '@src/app/features/educational-resources/models/resource.model';

@Component({
  selector: 'stx-dashboard-resources-panel',
  templateUrl: './dashboard-resources-panel.component.html',
  styleUrls: ['../dashboard-page/dashboard-page.component.scss']
})
export class DashboardResourcesPanelComponent extends BaseComponent {
  readonly weeklyAbstractsRoute = `/${cleftPublicationsFullRoute}`;
  readonly educationAndResearchRoute = educationAndResearchFullRoute;
  readonly covidResources = covidResources;
  readonly saqProtocolsResources: ResourceSection[] = saqProtocolsResources;
  // TODO: #47811
  // private _topMedicalMeeting$ = new BehaviorSubject<MedicalMeeting[]>([]);
  // topMedicalMeeting$ = this._topMedicalMeeting$.asObservable();

  constructor(private resourcesService: EducationalResourcesService) {
    super();
  }

  // TODO: #47811
  // ngOnInit(): void {
  // this.subSink.sink = this.resourcesService.fetchMedicalMeetings().subscribe(results => {
  // TODO: #9726 fix nulls in medical_meeting.category column
  //   this._topMedicalMeeting$.next(
  //     !results || results.length === 0
  //       ? []
  //       : results
  //           .filter(
  //             medicalMeeting => !!medicalMeeting.category && medicalMeeting.category !== MedicalMeetingCategory.PAST_EVENT_RECORDING
  //           )
  //           .slice(0, 1)
  //   );
  // });
  // }
}
