import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { getEducationalResourceUrl, OFFLINE_APP_DIR } from '@/src/app/features/educational-resources/utils/educational-resources.utils';
import { FileExtension, FileUtils, Mime } from '@/src/app/utils/file.utils';
import { Component } from '@angular/core';
import { NavigationSubTabEnum } from '@shared/components/navigation/model/nav-item-enum.enum';
import {
  patientRegistrationResource,
  patientReleaseFormResource,
  speechAssessmentMedicalForm,
  speechTreatmentMedicalForm
} from '@src/app/features/educational-resources/utils/shared-resources-definitions.utils';
import { OfflineAppService } from '../../../offline-app/offline-app.service';
import { Resource, ResourceSection } from '../../models/resource.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  templateUrl: './medical-forms-page.component.html',
  styleUrls: ['./medical-forms-page.component.scss']
})
export class MedicalFormsPageComponent {
  readonly PermissionEnum = PermissionEnum;
  readonly NavigationSubTabEnum = NavigationSubTabEnum;
  readonly patientSection = patientSection;
  readonly speechSection = speechSection;
  readonly orthoSection = orthoSection;
  readonly surgerySection = surgerySection;
  readonly nutritionSection = nutritionMedicalFormsSection;
  readonly offlineAppLinks = offlineAppSection.slice(0, 1);
  readonly practitionersList = offlineAppSection[1].resources[0];
  readonly comdlg = offlineAppSection[1].resources[1];

  constructor(private offlineAppService: OfflineAppService, private wsHelperService: WsHelperService) {}

  getPractitionersList() {
    this.wsHelperService
      .call(this.offlineAppService.getPractitionersCsvFile())
      .subscribe(file => FileUtils.downloadFile(file, 'stxlist.stu', Mime.CSV));
  }
  getEducationalResourceUrl(resource: Resource, langShortcut: string = '') {
    return getEducationalResourceUrl(resource, langShortcut);
  }
}

export const nutritionMedicalFormsSection: ResourceSection = {
  sectionTitle: 'treatment.nutrition',
  resources: [
    {
      label: 'medical_forms.nutrition.infant',
      resourceName: 'Nutrition_Infant_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'medical_forms.nutrition.toddler',
      resourceName: 'Nutrition_Toddler_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'medical_forms.nutrition.adolescent',
      resourceName: 'Nutrition_Adolescent_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'medical_forms.nutrition.adult',
      resourceName: 'Nutrition_Adult_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    }
  ]
};
export const patientSection: ResourceSection = {
  sectionTitle: 'patient.patient',
  resources: [
    patientRegistrationResource,
    patientReleaseFormResource,
    {
      label: 'medical_forms.printable_forms.declaration',
      resourceName: 'Declaration_Form_',
      translationList: [['as', 'bn', 'en', 'gu', 'hi', 'kn', 'ml', 'mr', 'or', 'pa', 'ta', 'te', 'ur']]
    }
  ]
};
const surgerySection: ResourceSection = {
  sectionTitle: 'treatment.surgery',
  resources: [
    {
      label: 'medical_forms.printable_forms.surgical_intervention',
      resourceName: 'Surgical_Intervention_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'medical_forms.printable_forms.surgery_follow_up',
      resourceName: 'Surgery_Follow-up_Visit_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    }
  ],
  isSeparated: true
};
const speechSection: ResourceSection = {
  sectionTitle: 'treatment.speech',
  resources: [speechAssessmentMedicalForm, speechTreatmentMedicalForm],
  isSeparated: true
};
const orthoSection: ResourceSection = {
  sectionTitle: 'treatment.orthodontics',
  resources: [
    {
      label: 'treatment.ortho_psio_assessment',
      resourceName: 'PSIO_Treatment_Start_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_psio_treatment',
      resourceName: 'PSIO_Treatment_End_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_md_assessment',
      resourceName: 'Mixed_Dentition_Treatment_Start_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_md_treatment',
      resourceName: 'Mixed_Dentition_Treatment_End_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_md_mid_assessment',
      resourceName: 'Mixed_Dentition_Intermediate_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_pd_assessment',
      resourceName: 'Permanent_Dentition_Treatment_Start_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_pd_treatment',
      resourceName: 'Permanent_Dentition_Treatment_End_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    },
    {
      label: 'treatment.ortho_pd_mid_assessment',
      resourceName: 'Permanent_Dentition_Intermediate_',
      translationList: [['en', 'es', 'fr', 'id', 'pt', 'vi', 'cn']]
    }
  ],
  isSeparated: false
};
export const offlineAppSection: ResourceSection[] = [
  {
    sectionTitle: 'medical_forms.offline_application.header',
    sectionDescription: 'medical_forms.offline_application.availability_info',
    isSectionDisabled: true,
    resources: []
  },
  {
    resources: []
  }
];
